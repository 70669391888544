<template>
  <div class="pa-0">
    <div class="mt-15" v-if="isLoad">
      <v-row class="px-15" v-for="i in 2" :key="i">
        <v-col cols="6" v-for="i in 4" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="items.length > 0">
      <div class="grid justify-items-center px-0 py-10">
        <v-col cols="11" class="pa-0 mt-10 pt-10">
          <v-row class="px-5">
            <v-col cols="12" class="text-left mb-4">
              <span class="font-bold text-3xl text-capitalize" v-if="bahasa"
                >Windows Kami</span
              >
              <span class="font-bold text-3xl text-capitalize" v-else
                >Our Windows</span
              >
            </v-col>
          </v-row>
          <v-row class="px-5">
            <v-col
              cols="12"
              sm="6"
              class="cursor-pointer mb-3 text-left"
              v-for="(window, index) in items"
              :key="'window' + index"
              @click="goTo('windows-detail', window.id)">
              <v-row>
                <v-col cols="3" class="">
                  <img
                    :src="baseUrlImage + window.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%" />
                </v-col>
                <v-col cols="9">
                  <div class="font-bold text-lg py-0">
                    {{ window.name || "-" }}
                  </div>
                  <div
                    class="font-lora text-base py-3"
                    v-html="strLimit(window.desc, 100)"></div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </div>
    </div>
    <div class="white grid justify-items-center px-0 py-10 text-left" v-else>
      <v-col cols="11" class="pa-0 mt-10 py-10">
        <v-row>
          <v-col cols="12" sm="6">
            <img
              src="@/assets/images/405.png"
              @error="
                $event.target.src = 'https://admin.bpdlh.id/assets/empty.png'
              "
              width="100%" />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="text-black flex flex-wrap content-center">
            <v-col cols="12" class="text-3xl font-bold pl-0">{{
              bahasa
                ? "Tidak Ada Data Windows yang Tersedia"
                : "No Windows Data Available"
            }}</v-col>
            <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
              {{ bahasa ? "kembali ke beranda" : "back to home" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoad: false,
      items: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
      lastUpdated: "",
    };
  },
  computed: {
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  watch: {
    bahasa() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
      };
      await axios
        .get(this.baseUrl + "/donor", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.items = response.data.data.data;
            this.page = response.data.data.current_page;
            this.pageCount = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },
    goTo(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
  },
};
</script>
